<template>
  <div id="div-tabela-kpis-by-municipio">
    <v-text-field
      v-if="numMunicipios > 10"
      v-model="search"
      append-icon="mdi-magnify"
      class="ml-auto mb-5 break-search"
      label="Procurar"
      hide-details
      single-line
    />
    <v-data-table
      class="data-table-kpis"
      multi-sort
      dense
      :headers="headers"
      :items="dadosKpisByMunicipio"
      :search.sync="search"
      :sort-by="['pendentes']"
      :sort-desc="['pendentes']"
      :options="{
        itemsPerPage: 10
      }"
      :headerProps="{
        sortByText: 'Ordenar Por'
      }"
      :hide-default-footer="numMunicipios <= 10"
      no-data-text="Nenhum registro disponível"
      no-results-text="Nenhum registro correspondente encontrado"
      :mobile-breakpoint="10"
    >
    <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ header }">
      <v-tooltip bottom v-if="traducaoIndicadores[h.value] != null">
        <template v-slot:activator="{ on }">
          <span v-on="on">{{h.text}}</span>
        </template>
        <span>{{traducaoIndicadores[h.value]}}</span>
      </v-tooltip>
      <span v-else>{{h.text}}</span>
    </template>
      <template v-slot:[`item.pendentes`]="{ item }">
        {{ item.pendentes | parseNumberToFloatBrIgnoringNaN }}
      </template>
      <template v-slot:[`item.psa`]="{ item }">
        {{ item.psa | parseNumberToFloatBrIgnoringNaN }} %
      </template>
      <template v-slot:[`item.pat`]="{ item }">
        {{ item.pat | parseNumberToFloatBrIgnoringNaN }}
      </template>
      <template v-slot:[`item.suspensos`]="{ item }">
        {{ item.suspensos | parseNumberToFloatBrIgnoringNaN }}
      </template>
      <template v-slot:[`item.concluidos`]="{ item }">
        {{ item.concluidos | parseNumberToFloatBrIgnoringNaN }}
      </template>
      <template v-slot:[`item.psfp`]="{ item }">
        {{ item.psfp | parseNumberToFloatBrIgnoringNaN }} %
      </template>
      <template v-slot:[`item.patc`]="{ item }">
        {{ item.patc | parseNumberToFloatBrIgnoringNaN }}
      </template>
      <template v-slot:[`item.compensacoes`]="{ item }">
        {{ item.compensacoes | parseNumberToFloatBrIgnoringNaN }}
      </template>
    </v-data-table>
  </div>
</template>

<script>

export default {
  name: "DashboardServicosVisaoMunicipiosKpisDataTable",
  props: {
    dadosKpisByMunicipio: {
      type: Array,
      required: true  
    },
    traducaoIndicadores: {
      type: Object,
      default: {}
    }
  },
  data: () => ({
    headers: [
      {
        text: 'IBGE',
        value: 'codigo_ibge'
      },
      {
        text: 'Município',
        value: 'nome_municipio',
        width: '100px'
      },
      {
        value: "pendentes",
        text: "Pendentes",
      },
      {
        value: "psa",
        text: "PSA",
      },
      {
        value: "pat",
        text: "PAT",
      },
      {
        value: "suspensos",
        text: "Suspensos",
      },
      {
        value: "concluidos",
        text: "Concluídos",
      },
      {
        value: "psfp",
        text: "PSFP",
      },
      {
        value: "patc",
        text: "PATC",
      },
      {
        value: "compensacoes",
        text: "Compensações",
      },
    ],
    search: ''
  }),
  computed: {
    numMunicipios() {
      return this.dadosKpisByMunicipio.length;
    },
  }
}

</script>

<style>
#div-tabela-kpis-by-municipio {
  min-height: 420px;
}
.data-table-kpis table tbody tr td {
  font-size: 12px;
  padding: 0px 8px !important;
}
.data-table-kpis table thead tr th {
  font-size: 12px;
  height: 25px;
  font-weight: 400;
}
</style>