var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"div-tabela-kpis-by-municipio"}},[(_vm.numMunicipios > 10)?_c('v-text-field',{staticClass:"ml-auto mb-5 break-search",attrs:{"append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('v-data-table',{staticClass:"data-table-kpis",attrs:{"multi-sort":"","dense":"","headers":_vm.headers,"items":_vm.dadosKpisByMunicipio,"search":_vm.search,"sort-by":['pendentes'],"sort-desc":['pendentes'],"options":{
      itemsPerPage: 10
    },"headerProps":{
      sortByText: 'Ordenar Por'
    },"hide-default-footer":_vm.numMunicipios <= 10,"no-data-text":"Nenhum registro disponível","no-results-text":"Nenhum registro correspondente encontrado","mobile-breakpoint":10},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(h){return {key:("header." + (h.value)),fn:function(ref){
    var header = ref.header;
return [(_vm.traducaoIndicadores[h.value] != null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(h.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.traducaoIndicadores[h.value]))])]):_c('span',[_vm._v(_vm._s(h.text))])]}}}),{key:"item.pendentes",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.pendentes))+" ")]}},{key:"item.psa",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.psa))+" % ")]}},{key:"item.pat",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.pat))+" ")]}},{key:"item.suspensos",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.suspensos))+" ")]}},{key:"item.concluidos",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.concluidos))+" ")]}},{key:"item.psfp",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.psfp))+" % ")]}},{key:"item.patc",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.patc))+" ")]}},{key:"item.compensacoes",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.compensacoes))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }